import React from 'react';
import styles from '../styles/Button.module.css';

const Button = ({ buttonId }) => {
  return (
    <form
      action="https://www.paypal.com/cgi-bin/webscr"
      method="post"
      target="_top"
    >
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value={buttonId} />
      <input
        className={styles.donateButton}
        type="image"
        src="https://lauriannwestcc.org/wp-content/uploads/2015/06/join-button.png"
        border="0"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      />
      <img
        alt=""
        border="0"
        src="https://www.paypal.com/en_US/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  );
};

export default Button;
