import React from 'react';
import Donate from '../components/donate/Donate';
import Layout from '../components/layout';

export default function donate() {
  return (
    <Layout>
      <Donate />
    </Layout>
  );
}
