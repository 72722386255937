import React from 'react';
import globalStyles from '../styles/Main.module.css';
import styles from '../styles/Donate.module.css';
import Button from './Button';
import { DonationTiers } from '../../utils/constants';

const Donate = () => {
  const donationRows = DonationTiers.map(({ LABEL, BUTTON_ID }) => (
    <div className={styles.donationRows}>
      <h2 className={styles.donationText}>{LABEL}</h2>
      <Button buttonId={BUTTON_ID} />
    </div>
  ));
  return (
    <>
      <div className={`${globalStyles.section} ${styles.donateContainer}`}>
        <h1 className={globalStyles.sectionTitle}>Donate</h1>
        <h2 className={globalStyles.standardText}>
          {'Please select a membership level: '}
        </h2>

        {donationRows}
      </div>
    </>
  );
};

export default Donate;
